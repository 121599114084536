/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://1i3ddao5x7.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        },
        {
            "name": "memesrcContentApi",
            "endpoint": "https://9qxcgrmenl.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        },
        {
            "name": "publicapi",
            "endpoint": "https://3l8mh1n9rf.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nzkx3ld6lbhmha2oci4ppx6spi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-somu3lf45rbmngpdoesgqijcyq",
    "aws_cognito_identity_pool_id": "us-east-1:2f8c43f2-fb95-4145-9e0f-90a1de3a5936",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_h6jZrGaJU",
    "aws_user_pools_web_client_id": "69c10qlprv9dgkg7j19bp6hceo",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "meme-20221129192452-hostingbucket-beta",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://meme-20221129192452-hostingbucket-beta.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "memesrc-generated-images190209-beta",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
